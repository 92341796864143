import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Opportunities from "../components/Opportunities/Opportunities"

const OpportunitiesPage = () => (
  <Layout>
    <SEO 
      title="Opportunities" 
      keywords="translation, localisation, localization, English to Tagalog, Tagalog to English, cultural translation, cultural naturalization, cultural naturalisation, production management, voice acting, voice actors, voice actor management, dubbing, voice over, Australia, Philippines, Sleigh Platform, subtitles, subtitling, language services, jobs, employment"/>
    <Opportunities />
  </Layout>
)

export default OpportunitiesPage
